var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Centered Slides  Option-2"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeCenterSlides2) + " ")];
      },
      proxy: true
    }])
  }, [_c('swiper', {
    staticClass: "swiper-centered-slides-2 p-1",
    attrs: {
      "options": _vm.swiperOptions,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
    }
  }, _vm._l(_vm.swiperData, function (data, index) {
    return _c('swiper-slide', {
      key: index,
      staticClass: "rounded swiper-shadow py-1 px-3 d-flex"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": data.icon,
        "size": "18"
      }
    }), _c('div', {
      staticClass: "swiper-text"
    }, [_vm._v(" " + _vm._s(data.text) + " ")])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }