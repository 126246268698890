var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Pagination"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codePagination) + " ")];
      },
      proxy: true
    }])
  }, [_c('swiper', {
    staticClass: "swiper-paginations",
    attrs: {
      "options": _vm.swiperOptions,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
    }
  }, [_vm._l(_vm.swiperData, function (data, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('b-img', {
      attrs: {
        "src": data.img,
        "fluid": ""
      }
    })], 1);
  }), _c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }